// Environment configuration
const isDesktop = process.env.REACT_APP_PLATFORM === 'desktop';
const isDevelopment = process.env.NODE_ENV === 'development';

// API base URL configuration
const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';

export const config = {
  apiBaseUrl,
  isDesktop,
  isDevelopment,
  // Add other configuration values here
} as const;

export type Config = typeof config;
